import { render } from "./ApexChartsGraph.vue?vue&type=template&id=25f72008&scoped=true"
import script from "./ApexChartsGraph.vue?vue&type=script&lang=ts"
export * from "./ApexChartsGraph.vue?vue&type=script&lang=ts"
script.render = render
script.__scopeId = "data-v-25f72008"
/* hot reload */
if (module.hot) {
  script.__hmrId = "25f72008"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('25f72008', script)) {
    api.reload('25f72008', script)
  }
  
  module.hot.accept("./ApexChartsGraph.vue?vue&type=template&id=25f72008&scoped=true", () => {
    api.rerender('25f72008', render)
  })

}

script.__file = "src/components/dashboard/ApexChartsGraph.vue"

export default script